/*
* External dependencies.
*/
import React, {useState, useContext, useEffect} from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import cx from 'classnames';
import selectStyles from 'helpers/dropdown';

/*
* Internal dependencies.
*/
import AppContext from 'context';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ModalNewProject = (props) => {
	const { showModal, dispatchEvent } = useContext(AppContext);

	const projectCategories = [
		{value: 'any', label: 'Any Status'},
		{value: 'draft', label: 'Draft'},
		{value: 'pending', label: 'Pending assignment'},
		{value: 'preparing', label: 'Preparing'},
		{value: 'in_progress', label: 'In progress'},
		{value: 'closed', label: 'Closed'},
	];

	let modules = {
		toolbar: [
			['bold', 'italic'],
			[{'list': 'ordered'}, {'list': 'bullet'}],
			['link'],
			['clean']
		]
	};

	return (
		<Modal
			show={showModal}
			onHide={() => {dispatchEvent('TOGGLE_MODAL');}} 
			aria-labelledby="contained-modal-title-vcenter"
		>
			<Modal.Header closeButton>
				<Modal.Title>New project request</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Form className="form">
					<Form.Group className="form-group col-md-6">
						<Form.Label htmlFor="category">Category *</Form.Label>

						<Select class="select" id="category" options={projectCategories} styles={selectStyles} />
					</Form.Group>

					<Form.Group className="form-group">
						<Form.Label htmlFor="name">Project name *</Form.Label>
						
						<Form.Control 
							id="name" 
							type="text" 
							placeholder="Give your project a name" 
						/>
					</Form.Group>

					<Form.Group className="form-group">
						<Form.Label htmlFor="description">Description *</Form.Label>

						<ReactQuill
							theme={"snow"}
							value={""}
							modules={modules}
							bounds={'.app'}
							placeholder={"What is this project about?"}
						/>
					</Form.Group>

					<Form.Group className="form-group file-area">
						<h6>Add Files</h6>

						<div className="form-group-inner">
							<p>Drag files here to add them to this project, or</p>

							<div className="form-group-actions">
								<Form.Label htmlFor="upload">Upload from computer</Form.Label>

								<Button variant="teritary">Import from Brand Assets</Button>
							</div>
						</div>
						
						<Form.Control 
							id="upload"
							type="file" 
							placeholder="Give your project a name" 
						/>
					</Form.Group>
				</Form>
			</Modal.Body>

			<Modal.Footer>
				<Button variant="quaternary" onClick={() => dispatchEvent('TOGGLE_MODAL')}>Continue</Button>
			</Modal.Footer>
		</Modal>
	); 
};

export default ModalNewProject;
