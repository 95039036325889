/**
 * External dependencies.
 */
import React, { useContext } from 'react';
import { Nav, Tab, Col, Row, Button, Badge, Form } from 'react-bootstrap';

/**
 * Internal dependencies.
 */
import AppContext from 'context';
import LayoutBase from 'layouts/layout-base/layout-base';
import Section from 'blocks/section/section';
import WidgetMessage from 'components/widget-message/widget-message';
import WidgetContent from 'components/widget-content/widget-content';
import Widget from 'components/widget/widget';
import File from 'components/file/file';


import Image from 'images/img1.jpg';

const FileSpecific = () => {
	const messages = [
		{
			"avatar" : {
				"image" : require("images/andrea1.jpg")
			},
			"name" : "Andrea Perdomo",
			"date" : "2:04",
			"text" : "<p>Hi Isidro, how is everything?</p><p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p><p>Thanks,<br />Andrea</p>",
			"files" : [
				{
					"title" : "Ironside Homepage Desktop",
					"subtitle" : "5.5 MB",
					"url" : "#",
					"image" : ""
				},
				{
					"title" : "Ironside Homepage Mobile",
					"subtitle" : "3.9 MB",
					"url" : "#",
					"image" : ""
				}
			]
		},
		{
			"dateCurrent" : "Friday, December 4th",
			"avatar" : {
				"initials" : "I",
				"color" : "#2ED9D9",
				"background" : "#ECFAF6",
			},
			"name" : "Isidro Hurtado",
			"date" : "2:45",
			"text" : "<p>Thanks Andrea!</p><p>Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt.</p>"
		}
	]



	const files = [
		{
			"image" : require("images/pdf.png"),
			"title" : "reakt-56175-ironsideh.zip",
			"updated" : false,
			"url" : "/"
		},
		{
			"title" : "Homepage_Mobile_V2.png",
			"updated" : false,
			"url" : "/"
		},

		{
			"title" : "Homepage_Mobile_V1.png",
			"updated" : false,
			"url" : "/"
		},

		{
			"title" : "Ironside_Homepage_7.jpg",
			"updated" : false,
			"url" : "/"
		},
		{
			"image" : require("images/f1.jpg"),
			"title" : "Ironside_Homepage_Superside_V6.jpg",
			"updated" : true,
			"url" : "/"
		},
		{
			"image" : require("images/f2.jpg"),
			"title" : "Ironside_Homepage_Superside_V5.jpg",
			"updated" : false,
			"url" : "/"
		},
		{
			"title" : "Ironside_Homepage_Superside_V4.jpg",
			"updated" : false,
			"url" : "/"
		},
		{
			"image" : require("images/f3.jpg"),
			"title" : "Ironside_Homepage_Superside_V3.jpg",
			"updated" : false,
			"url" : "/"
		},
		{
			"title" : "Ironside_Homepage_Superside_V2.jpg",
			"updated" : false,
			"url" : "/"
		},
		{
			"title" : "Ironside_Homepage_Superside_V1.jpg",
			"updated" : false,
			"url" : "/"
		}
	]


	return (
		<LayoutBase>
			<Section className="section--alt">
				<Section.Head>
					<Button variant="def" href="project-description-message">
						<i className="ico-angle-left"></i>

						DesktopHomepage V1.png
					</Button>		

					<Section.HeadAside>
						<Button variant="outline-secondary btn-outline-secondary-icon">
							<i className="ico-upload"></i>
						</Button>

						<Button variant="outline-secondary">Download</Button>
					</Section.HeadAside>
				</Section.Head>

				<Section.Body>
					<img src={Image} alt="" />
				</Section.Body>

				<Section.Aside>
					<div className="tabs-alt tabs-alt--primary">
						<Tab.Container defaultActiveKey="first" className="tabs">
							<Nav className="tab-head">
								<Button variant="link">
									<i className="ico-angle-right"></i>
								</Button>
								<Nav.Item>
									<Nav.Link eventKey="first">Messages</Nav.Link>
								</Nav.Item>
								
								<Nav.Item>
									<Nav.Link eventKey="second">Files</Nav.Link>
								</Nav.Item>
							</Nav>

							<Tab.Content>
								<Tab.Pane eventKey="first">
									<WidgetMessage 
										messages={messages}
									/>
								</Tab.Pane>
								
								<Tab.Pane eventKey="second">
									<div className="files">
										<Form className="form-search">
											<Form.Group>
												<Form.Control type="text" placeholder="Search by file name" />
											</Form.Group>

											<Button variant="icon" type="submit">
												<i className="ico-search-alt"></i>
											</Button>
										</Form>

										{files?.map((file, index) => {
											return (
												<File 
													key={index}
													image={file.image}
													title={file.title}
													updated={file.updated}
													url={file.url}
												/>
											) 
										})}
									</div>
								</Tab.Pane>
							</Tab.Content>
						</Tab.Container>
					</div>
				</Section.Aside>
			</Section>
		</LayoutBase>
	);
};

export default FileSpecific;
