/**
 * External dependencies.
 */
import React, { useContext } from 'react';
import { Nav, Tab, Button, Table, Badge } from 'react-bootstrap';

/**
 * Internal dependencies.
 */
import AppContext from 'context';
import LayoutBaseInvoices from 'layouts/layout-base-invoices/layout-base-invoices';
import Section from 'blocks/section/section';
import Report from 'blocks/report/report';
import WidgetOptions from 'blocks/widget-options/widget-options';

const Invoices = () => {
	const { dispatchEvent } = useContext(AppContext);
	
	return (
		<LayoutBaseInvoices>
			<Section className="section--secondary">
				<Section.Head>
					<h1>Admin Console</h1>
				</Section.Head>

				<Section.Body>
					<div className="tabs">
						<Tab.Container defaultActiveKey="first" className="tabs">
							<Nav className="tab-head tab-head--primary">
								<Nav.Item>
									<Nav.Link eventKey="first">Settings</Nav.Link>
								</Nav.Item>
								
								<Nav.Item>
									<Nav.Link eventKey="second">Reports</Nav.Link>
								</Nav.Item>

								<Nav.Item>
									<Nav.Link eventKey="third">Invoices</Nav.Link>
								</Nav.Item>

								<Nav.Item>
									<Nav.Link eventKey="fourth">Project Management</Nav.Link>
								</Nav.Item>
							</Nav>

							<Tab.Content>
								<Tab.Pane eventKey="first">
									<WidgetOptions />
								</Tab.Pane>

								<Tab.Pane eventKey="second">
									<Report />
								</Tab.Pane>
								
								<Tab.Pane eventKey="third">
									<Table className="table--primary">
										<tbody>
											<tr>
												<td>
													<h5>
														<i className="ico-doc"></i>
														

														Invoice #36498
													</h5>
												</td>

												<td>
													<p>USD 6000</p>
												</td>

												<td>
													<p>14 Jan, 3:46AM</p>
												</td>

												<td>
													<Badge bg="green">Paid</Badge>
												</td>

												<td>
													<Button variant="outline-secondary">View</Button>
												</td>
											</tr>

											<tr>
												<td>
													<h5>
														<i className="ico-doc"></i>
														

														Invoice #36498
													</h5>
												</td>

												<td>
													<p>USD 6000</p>
												</td>

												<td>
													<p>14 Jan, 3:46AM</p>
												</td>

												<td>
													<Badge bg="green">Paid</Badge>
												</td>

												<td>
													<Button variant="outline-secondary">View</Button>
												</td>
											</tr>
										</tbody>
									</Table>
								</Tab.Pane>

								<Tab.Pane eventKey="fourth">
									<h4>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil, accusantium, officiis aliquid eligendi, voluptatem recusandae, dolore repellat saepe excepturi quidem eos quo veritatis. Sapiente totam facere amet cum. Voluptatum, voluptate.</h4>
								</Tab.Pane>
							</Tab.Content>
						</Tab.Container>
					</div>
				</Section.Body>
			</Section>
		</LayoutBaseInvoices>
	);
};

export default Invoices;
