/*
* External dependencies.
*/
import React from 'react';
import { Form, Button } from 'react-bootstrap';

/*
* Internal dependencies.
*/
import EditImage from 'images/edit-alt.png';

const WidgetMessage = ({ date, messages }) => {
	return (
		<div className="widget-message">
			{date && (
				<p className="widget__meta">{date}</p>
			)}

			<div className="widget__body">
				{messages.map((message, index) => {
					return (
						<div className="widget__item-outer" key={index}>
							{message.dateCurrent && (
								<p className="widget__date-day">{message.dateCurrent}</p>
							)}

							<div className="widget__item">

								{message.avatar.image ? (
									<div 
										className="avatar-alt"
										style={{ backgroundImage: `url(${message.avatar.image})` }}
									></div>
								) : (
									<div 
										className="avatar-alt"
										style={{backgroundColor: message.avatar.background, color: message.avatar.color, }}
									>{message.avatar.initials}</div>
								)}

								<h5>{message.name} <span>{message.date}</span></h5>

								{message.subtitle && (<h6>{message.subtitle}</h6>)}

								{message.file && (
									<div className="widget__file">
										<a href={message.file.url}></a>

										<div className="widget__file-image">
											<i className="ico-pen"></i>
										</div>

										<h5>
											{message.file.title}

											<span>{message.file.subtitle}</span>
										</h5>

										<i className="ico-angle-left"></i>
									</div>
								)}
								
								<div dangerouslySetInnerHTML={{ __html: message.text }} />

								{message.files && (
									<div className="widget__files">
										<h6>2 files <i className="ico-angle-down-dark"></i></h6>

										{message.files.map((file, index) => {
											return (
												<div className="widget__file" key={index}>
													<a href={file.url}></a>

													<div 
														className="widget__file-image"
														style={{ backgroundImage: `url(${file.image})` }}
													/>

													<h5>
														{file.title}

														<span>{file.subtitle}</span>
													</h5>
												</div>
											) 
										})}
									</div>
								)}
							</div>
						</div>
					) 
				})}
			</div>

			<div className="widget__foot">
				<Form className="form-message">
					<Form.Group className="form-body">
						<Form.Control type="text" placeholder="Ask a question or post an update..." />

						<Button variant="primary" type="submit">
							<i className="ico-send"></i>
						</Button>
					</Form.Group>

					<Form.Group className="form-bar">
						<img src={EditImage} alt="" />
					</Form.Group>
				</Form>
			</div>
		</div>
	); 
};

export default WidgetMessage;
