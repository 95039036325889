/*
* External dependencies.
*/
import React from 'react';

/*
* Internal dependencies.
*/
import Header from 'blocks/header/header';
import ModalNewProject from 'components/modal-new-project/modal-new-project';

const LayoutBaseBrand = ({ children }) => (
	<div className="wrapper">
		<Header activeNavLink="link-2" />

		<div className="content">
			{children}
		</div>

		<ModalNewProject />
	</div>
);

export default LayoutBaseBrand;
