/*
* External dependencies.
*/
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

/*
* Internal dependencies.
*/
import Home from 'pages/home';
import NoProjects from 'pages/no-projects';
import MyProjects from 'pages/my-projects';
import ProjectDescriptionMessage from 'pages/project-description-message';
import FileSpecific from 'pages/file-specific';
import Invoices from 'pages/invoices';
import ProfileSettings from 'pages/profile-settings';
import Brand from 'pages/brand';
import TeamMembers from 'pages/team-members';

const Routes = () => (
	<Router>
		<Switch>
			<Route
				path="/"
				exact
				component={Home}
			/>

			<Route
				path="/no-projects"
				exact
				component={NoProjects}
			/>

			<Route
				path="/my-projects"
				exact
				component={MyProjects}
			/>

			<Route
				path="/project-description-message"
				exact
				component={ProjectDescriptionMessage}
			/>

			<Route
				path="/file-specific"
				exact
				component={FileSpecific}
			/>

			<Route
				path="/invoices"
				exact
				component={Invoices}
			/>

			<Route
				path="/profile-settings"
				exact
				component={ProfileSettings}
			/>

			<Route
				path="/brand"
				exact
				component={Brand}
			/>

			<Route
				path="/team-members"
				exact
				component={TeamMembers}
			/>
		</Switch>
	</Router>
);

export default Routes;
