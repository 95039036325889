/**
 * External dependencies.
 */
import React, { useContext } from 'react';
import {Nav, Tab, ButtonGroup, Button, Table, Badge, Form} from 'react-bootstrap';

/**
 * Internal dependencies.
 */
import AppContext from 'context';
import LayoutBase from 'layouts/layout-base/layout-base';
import Section from 'blocks/section/section';
import {useTable, useSortBy} from 'react-table';

function ReactTable({ columns, data }) {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable({columns, data,}, useSortBy);

	return (
		<table className="table">
			<thead key="thead">
			{
				headerGroups.map(headerGroup => (
					<tr>
						{
							headerGroup.headers.map(column => (
								<th {...column.getHeaderProps(column.getSortByToggleProps())}>
									{// Render the header
										column.render('Header')
									}
									<span>
										<div className="table-sort">
											<img src={column.isSorted? (column.isSortedDesc? "" : require("../images/sort-up.svg").default) : require("../images/sort-up.svg").default}></img>
											<img src={column.isSorted? (column.isSortedDesc? require("../images/sort-down.svg").default : "") : require("../images/sort-down.svg").default}></img>
										</div>
                  					</span>
								</th>
							))}
					</tr>
				))
			}
			</thead>
			<tbody {...getTableBodyProps()} key="tbody">
			{
				rows.map(row => {
					prepareRow(row);
					return (
						<tr>
							{
								row.cells.map(cell => {
									return (
										<td {...cell.getCellProps()}>
											{
												cell.render('Cell')}
										</td>
									)
								})}
						</tr>
					)
				})}
			</tbody>
		</table>
	)
}

const Home = () => {
	const { dispatchEvent } = useContext(AppContext);

	const columns = React.useMemo(() => [
		{
			Header: "Project Name",
			accessor: 'name',
			Cell: cellInfo => {
				let rowData = cellInfo.row.original;

				let backgroundColor;
				if(rowData.status === 'Waiting'){
					backgroundColor = '#993BC6';
				}
				else if(rowData.status === 'Open'){
					backgroundColor = '#2FD9D9';
				}
				else if(rowData.status === 'Closed'){
					backgroundColor = '#9690A5';
				}

				return (
					<h4>
						<i className="ico-circle" style={{backgroundColor: backgroundColor}}></i>

						{rowData.name}

						<div>
							<span>{rowData.lastSender}: </span> <span>{rowData.lastMessage}</span>
						</div>
					</h4>
				)
			}
		},
		{
			Header: "Status",
			accessor: 'status',
			Cell: cellInfo => {
				let rowData = cellInfo.row.original;

				let backgroundColor;
				let color;
				if(rowData.status === 'Waiting'){
					backgroundColor = '#F7F0FB';
					color = '#993BC6'
				}
				else if(rowData.status === 'Open'){
					backgroundColor = '#ECFAF6';
					color = '#2FD9D9';
				}
				else if(rowData.status === 'Closed'){
					backgroundColor = '#ECFAF6';
					color = '#9690A5';
				}

				return (
					<div className="badge" style={{backgroundColor, color}}>{rowData.status}</div>
				);
			}
		},
		{
			Header: "Deadline",
			accessor: 'deadline'
		},
		{
			Header: "Category",
			accessor: 'category'
		}
	]);

	const data = React.useMemo(() => [
		{
			name: 'Ironside Homepage Layout',
			status: 'Waiting',
			deadline: '17 Nov, 1:38PM',
			category: 'Landing Page Design',
			lastSender: 'You',
			lastMessage: 'Thanks Andrea!'
		},
		{
			name: 'Noah Mailer Box Design',
			status: 'Open',
			deadline: '17 Nov, 1:38PM',
			category: 'Print & Packaging',
			lastSender: 'You',
			lastMessage: 'This is open task, we can...'
		},
		{
			name: 'Headstrong Concepts',
			status: 'Closed',
			deadline: '17 Nov, 1:38PM',
			category: 'Web Design',
			lastSender: 'You',
			lastMessage: 'All layout approved. We...'
		}
	]);

	return (
		<LayoutBase>
			<Section>
				<Section.Head>
					<h1>Projects</h1>
				</Section.Head>

				<Section.Body>
					<div className="tabs">
						<Tab.Container defaultActiveKey="first" className="tabs">
							<Nav className="tab-head">
								<Nav.Item>
									<Nav.Link eventKey="first">All Projects <i className="ico-angle-down-dark"></i></Nav.Link>
								</Nav.Item>

								<Nav.Item>
									<Nav.Link eventKey="second">Status <i className="ico-angle-down-dark"></i></Nav.Link>
								</Nav.Item>

								<Nav.Item style={{marginTop: -10}}>
                                    <Form className="form-search">
                                        <Form.Group>
                                            <Form.Control type="text" placeholder="Search by project name"/>
                                        </Form.Group>

										<Button variant="icon" type="submit">
											<i className="ico-search-alt"></i>
										</Button>
                                    </Form>
								</Nav.Item>

								<ButtonGroup>
									<Button variant="icon active">
										<i className="ico-grid"></i>
									</Button>

									<Button variant="icon">
										<i className="ico-grid-box"></i>
									</Button>
								</ButtonGroup>
							</Nav>

							<Tab.Content>
								<Tab.Pane eventKey="first">
									<ReactTable columns={columns} data={data}></ReactTable>
								</Tab.Pane>

								<Tab.Pane eventKey="second">
									<h4>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odit eos consectetur veniam saepe culpa corporis, sint Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque doloremque ex fugiat laudantium quidem dolor perspiciatis, architecto modi vitae at iusto, commodi maiores et. Corporis quos blanditiis ratione nisi reprehenderit. modi similique cumque asperiores sapiente minus velit porro excepturi quae harum, itaque inventore libero!</h4>
								</Tab.Pane>
							</Tab.Content>
						</Tab.Container>
					</div>
				</Section.Body>
			</Section>
		</LayoutBase>
	);
};

export default Home;
