/*
* External dependencies.
*/
import React from 'react';
import cx from 'classnames';

const Section = ({ className, children }) => (
	<section className={cx('section', className)}>
		{children}
	</section>
);

const SectionHead = ({ children }) => (
	<header className="section__head">
		{children}
	</header>
);

const SectionHeadAside = ({ children }) => (
	<header className="section__head-aside">
		{children}
	</header>
);

const SectionBody = ({ children }) => (
	<div className="section__body">
		{children}
	</div>
);

const SectionBodyInner = ({ children }) => (
	<div className="section__body-inner">
		{children}
	</div>
);

const SectionAside = ({ children }) => (
	<div className="section__aside">
		{children}
	</div>
);

const SectionInner = ({ children }) => (
	<div className="section__inner">
		{children}
	</div>
);

const SectionActions = ({ children }) => (
	<div className="section__actions">
		{children}
	</div>
);

Section.Head = SectionHead; 
Section.HeadAside = SectionHeadAside; 
Section.BodyInner = SectionBodyInner;
Section.Aside = SectionAside;
Section.Body = SectionBody;
Section.Inner = SectionInner;
Section.Actions = SectionActions;

export default Section;
