/*
* External dependencies.
*/
import React from 'react';
import { Button } from 'react-bootstrap';

const File = ({ image, title, updated, url }) => {
	return (
		<div className="file" onClick={() => {window.location.href = url}}>
			<div 
				className="file__image"
				style={{ backgroundImage: `url(${image})` }}
			></div>

			<h6>
				{title}

				{updated && (<span>Updated</span>)}
			</h6>

			<Button variant="icon">
				<i className="ico-dots"></i>
			</Button>
		</div>
	); 
};

export default File;
