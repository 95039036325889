/*
* External dependencies
*/
import React, { useState } from 'react';

/*
* Internal dependencies
*/
import Routes from 'blocks/routes/routes';
import AppContext from 'context';

const App = () => {
	const [ showModal, setShowModal ] = useState(false);
	const [ showModalMembers, setShowModalMembers ] = useState(false);

	const dispatchEvent = (actionType, payload) => {
		switch (actionType) {
			case 'TOGGLE_MODAL':
				setShowModal(!showModal)

				return;
			case 'TOGGLE_MODAL_MEMBERS':
				setShowModalMembers(!showModalMembers)

				return;
			default:
				return;
		}
	};

	return (
		<AppContext.Provider value={{ dispatchEvent, showModal, showModalMembers }}>
			<Routes />
		</AppContext.Provider>
	);
}

export default App;
 