/*
* External dependencies.
*/
import React from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';

/*
* Internal dependencies.
*/
import Visa from 'images/visa.png';

const WidgetOptions = () => {
	return (
		<div className="widget-options">
			<div className="widget__group">
				<h3 className="widget__title">Team Info</h3>

				<Form className="form form--small">
					<Form.Group className="form-group">
						<Form.Label htmlFor="team-name">Team Name</Form.Label>
						
						<Form.Control 
							id="team-name" 
							type="text" 
							placeholder="Reakt" 
						/>
					</Form.Group>

					<Form.Group className="form-group">
						<Form.Label htmlFor="industry">Industry</Form.Label>
						
						<Form.Control 
							id="industry" 
							type="text" 
							placeholder="Agency" 
						/>
					</Form.Group>

					<Button variant="green">Save</Button>
				</Form>
			</div>

			<div className="widget__group">
				<h3 className="widget__title">Payment Methods</h3>

				<ul className="widget__items">
					<li>
						<div>
							<img src={Visa} alt="" />

							<h4>**** 8100</h4>

							<h6>Expires 7/2024</h6>
						</div>

						<div>
							<button style={{color: "#2ED9D9"}}>
								<i className="ico-check"></i> Your default
							</button>

							<Button variant="link">
								<i className="ico-dots-alt"></i>
							</Button>
						</div>
					</li>

					<li>
						<div>
							<img src={Visa} alt="" />

							<h4>**** 8100</h4>

							<h6>Expires 7/2024</h6>
						</div>

						<div>
							<button>Make default</button>

							<Button variant="link">
								<i className="ico-dots-alt"></i>
							</Button>
						</div>
					</li>
				</ul>

				<Button variant="green">Add payment method</Button>

				<p>Contact <a href="mailto:payment@superside.com">payment@superside.com</a> if you want to disconnect a bank account</p>
			</div>

			<div className="widget__group">
				<h3 className="widget__title">Billing Details</h3>

				<Form className="form form--details">
					<Row>
						<Col md="6">
							<Form.Group className="form-group">
								<Form.Label htmlFor="company-name">Company Name</Form.Label>
								
								<Form.Control 
									id="company-name" 
									type="text" 
									placeholder="PYLIT CORP" 
								/>
							</Form.Group>

							<Form.Group className="form-group">
								<Form.Label htmlFor="first-name">Contact First Name</Form.Label>
								
								<Form.Control 
									id="first-name" 
									type="text" 
									placeholder="Isidro" 
								/>
							</Form.Group>

							<Form.Group className="form-group">
								<Form.Label htmlFor="street">Street</Form.Label>
								
								<Form.Control 
									id="street" 
									type="text" 
									placeholder="3322 W. Eastwood Ave, Apt 3" 
								/>
							</Form.Group>

							<Form.Group className="form-group">
								<Form.Label htmlFor="postcode">Postcode</Form.Label>
								
								<Form.Control 
									id="postcode" 
									type="text" 
									placeholder="60625" 
								/>
							</Form.Group>
						</Col>

						<Col md="6">
							<Form.Group className="form-group">
								<Form.Label htmlFor="billing-email">Billing Email</Form.Label>
								
								<Form.Control 
									id="billing-email" 
									type="text" 
									placeholder="ihurtado@reakt.com" 
								/>
							</Form.Group>

							<Form.Group className="form-group">
								<Form.Label htmlFor="last-name">Contact Last Name</Form.Label>
								
								<Form.Control 
									id="last-name" 
									type="text" 
									placeholder="Hurtado" 
								/>
							</Form.Group>

							<Form.Group className="form-group">
								<Form.Label htmlFor="city">City</Form.Label>
								
								<Form.Control 
									id="city" 
									type="text" 
									placeholder="Chicago" 
								/>
							</Form.Group>

							<Form.Group className="form-group">
								<Form.Label htmlFor="country">Country</Form.Label>
								
								<Form.Control 
									id="country" 
									type="text" 
									placeholder="USA" 
								/>
							</Form.Group>
						</Col>
					</Row>		

					<Button variant="green">Save</Button>
				</Form>
			</div>
		</div>
	); 
};

export default WidgetOptions;
