/*
* External dependencies.
*/
import React from 'react';
import cx from 'classnames';
import { Row, Col } from 'react-bootstrap';

const Gadget = ({ title, files }) => {
	return (
		<div className="gadget">
			<div className="gadget__body">
				<h4 className="gadget__title">{title}</h4>

				<div className="gadget__items">
					<Row>
						{files.map((file, index) => {
							return (
								<Col xs={6} md={3} key={index}>
									<div className="gadget__item">
										<div className={cx('gadget__image', { "gadget__image--dashed" : !file.image })}>
											{file.image ? (
												<div 
													className="gadget__image-inner"
													style={{ backgroundImage: `url(${file.image})` }}
												></div>
											) : (
												<button>
													<i className="ico-plus"></i>
												</button>
											)}
										</div>

										<h4>{file.title}</h4>

										<h5>{file.items ? (file.items + " items") : ("Empty Folder")}</h5>
									</div>
								</Col>
							) 
						})}
					</Row>
				</div>
			</div>
			
			<div className="gadget__aside">
				<div>
					<i className="ico-file"></i>

					Select a file to view details
				</div>
			</div>
		</div>		
	); 
};

export default Gadget;
