/*
* External dependencies.
*/
import React from 'react';

const WidgetContent = ({ title, subtitle, children }) => {
	return (
		<div className="widget-content">
			{title && (<h3 className="widget__title">{title}</h3>)}
			
			<div className="widget__inner">
				{subtitle && (
					<div className="widget__head">
						<h4>{subtitle}</h4>
					</div>
				)}
				
				<div className="widget__body">{children}</div>
			</div>
		</div>
	); 
};

export default WidgetContent;
