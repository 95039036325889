/*
* External dependencies.
*/
import React, {useContext, useState} from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

/*
* Internal dependencies.
*/
import AppContext from 'context';

const ModalAddMembers = (props) => {
	const { showModalMembers, dispatchEvent } = useContext(AppContext);
	const [memberCount, setMemberCount] = useState(3);

	return (
		<Modal 
			show={showModalMembers} 
			onHide={() => {dispatchEvent('TOGGLE_MODAL_MEMBERS');}} 
			aria-labelledby="contained-modal-title-vcenter"
			className="modal-alt"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>Add team members</Modal.Title>

				<p>Invite co-workers to collaborate on your team’s projects</p>
			</Modal.Header>

			<Modal.Body>
				<Form className="form">
					{
						function() {
							let inputs = [];

							for (let i = 0; i < memberCount; i++) {
								inputs.push(
									<Form.Group className="form-group">
										<Form.Label htmlFor="member">Add member</Form.Label>

										<Form.Control
											id="member"
											type="text"
											placeholder="Enter email address"
										/>
									</Form.Group>
								)
							}

							return inputs;
						}()
					}
					
					<Button variant="link" onClick={addEntries}>
						<i className="ico-plus"></i> Add more
					</Button>
				</Form>
			</Modal.Body>

			<Modal.Footer>
				<Button onClick={()=>dispatchEvent('TOGGLE_MODAL_MEMBERS')} variant="quaternary">Invite</Button>
			</Modal.Footer>
		</Modal>
	);

	function addEntries(){
		setMemberCount(memberCount+1);
	}
};

export default ModalAddMembers;
