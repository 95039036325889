/*
* External dependencies.
*/
import React from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';

const WidgetSettings = () => {
	return ( 
		<div className="widget-options widget-options--settings">
			<div className="widget__group">
				<h3 className="widget__title">Profile</h3>

				<Form className="form form--profile">
					<Form.Group className="form-group file-area">
						<h6>Profile Image</h6>

						<div className="form-group-inner">
							<p>Drag your photo</p>

							<div className="form-group-actions">
								<Form.Label htmlFor="upload">Or upload from computer</Form.Label>
							</div>
						</div>
						
						<Form.Control 
							id="upload"
							type="file" 
							placeholder="Give your project a name" 
						/>
					</Form.Group>

					<Row>
						<Col md={6}>
							<Form.Group className="form-group">
								<Form.Label htmlFor="first-name">First Name</Form.Label>
								
								<Form.Control 
									id="first-name" 
									type="text" 
									placeholder="Isidro" 
								/>
							</Form.Group>
						</Col>

						<Col md={6}>
							<Form.Group className="form-group">
								<Form.Label htmlFor="last-name">Last Name</Form.Label>
								
								<Form.Control 
									id="last-name" 
									type="text" 
									placeholder="Hurtado" 
								/>
							</Form.Group>
						</Col>

						<Col md={6} className="form-col">
							<Form.Group className="form-group">
								<Form.Label htmlFor="email">Email</Form.Label>
								
								<Form.Control 
									id="email" 
									type="text" 
									placeholder="ihurtado@reakt.com" 
								/>
							</Form.Group>
						</Col>

						<Col md={6}>
							<Form.Group className="form-group">
								<Form.Label htmlFor="phone">Phone</Form.Label>
								
								<Form.Control 
									id="phone" 
									type="text" 
									placeholder="+16307152278" 
								/>
							</Form.Group>
						</Col>
					</Row>

					<Button variant="green">Save</Button>
				</Form>
			</div>

			<div className="widget__group">
				<h3 className="widget__title">Change password</h3>

				<Form className="form form--small">
					<Form.Group className="form-group">
						<Form.Label htmlFor="old-password">Old Password</Form.Label>
						
						<Form.Control 
							id="old-password" 
							type="password" 
							placeholder="•••••••••••••" 
						/>

						<i className="ico-eye"></i>
					</Form.Group>

					<Form.Group className="form-group">
						<Form.Label htmlFor="new-password">New Password</Form.Label>
						
						<Form.Control 
							id="new-password" 
							type="password" 
						/>
					</Form.Group>

					<Button variant="green">Save</Button>
				</Form>
			</div>
		</div>
	); 
};

export default WidgetSettings;
