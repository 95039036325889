const selectStyles = {
    option: (provided, state) => {
        return {
            color: state.isFocused ? '#993BC6' : '#232128',
            padding: 15,
            fontWeight: 'bold',
            backgroundColor: state.isFocused? '#FBF5FE' : '',
            borderRadius: 8
        }
    },
    control: (provided, state) => {
        let borderColor = state.menuIsOpen? '#CBB1D7': '#E0DCE9';

        return {
            border: `1px solid ${borderColor}`,
            boxSizing: 'border-box',
            boxShadow: '0px 1px 1px rgb(32 36 46 / 1%)',
            borderRadius: '6px',
            height: 45,
            fontSize: 15,
            color: '#211D1B',
            paddingLeft: 20,
            display: 'flex'
        }
    },
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    },
    indicatorSeparator: (provided, state) => {
        //Hides the separator line
    },
    indicatorsContainer: (provided, state) => {
        let rotate = state.selectProps.menuIsOpen? 'rotate(180deg)' : 'rotate(0deg)';

        return {
            ...provided,
            transform: rotate
        }
    },
    placeholder: (provided, state) => {
        let color = state.isFocused? '#993BC6' : '#A29EAD';

        return {
            color
        }
    },
    valueContainer: (provided, state) => ({
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        padding: 0,
        flex: 1
    }),
    menu: (provided, state) => ({
        ...provided,
        zIndex: 1000,
        padding: '15px 10px'
    })
}

export default selectStyles;
