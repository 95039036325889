/**
 * External dependencies.
 */
import React, { useContext } from 'react';
import { Nav, Tab, Button, Table, Badge } from 'react-bootstrap';

/**
 * Internal dependencies.
 */
import AppContext from 'context';
import LayoutBaseBrand from 'layouts/layout-base-brand/layout-base-brand';
import Section from 'blocks/section/section';
import Report from 'blocks/report/report';
import WidgetOptions from 'blocks/widget-options/widget-options';
import Notification from 'blocks/notification/notification';
import Gadget from 'components/gadget/gadget';



const Invoices = () => {
	const { dispatchEvent } = useContext(AppContext);

	const files = [
		{
			"image" : require('images/f11.jpg'),
			"title" : "Ironside",
			"items" : "5"
		},
		{
			"image" : require('images/f12.jpg'),
			"title" : "Noah",
			"items" : "4"
		},
		{
			"image" : require('images/f13.jpg'),
			"title" : "Headstrong",
			"items" : "8"
		},
		{
			"image" : "",
			"title" : "Images",
			"items" : ""
		},
		{
			"image" : "",
			"title" : "Templates",
			"items" : ""
		},
		{
			"image" : "",
			"title" : "Logos",
			"items" : ""
		}
	]
	
	return (
		<LayoutBaseBrand>
			<Section className="section--quaternary">
				<Section.Head>
					<h1>Brand</h1>

					<h6>Reakt</h6>
				</Section.Head>

				<Section.Body>
					<Notification
						height="149.02"
						title="All your files & templates — always up to date"
						text="Add your logo, PowerPoint templates, or brand images, then share with your team to make sure you're all on the same page."
					/>

					<Section.Actions> 
						<Button variant="link">
							<i className="ico-share"></i>

							Share
						</Button>

						<Button variant="link">
							<i className="ico-copy"></i>
							
							Select All
						</Button>

						<Button variant="link">
							<i className="ico-folder-big"></i>
							
							New Folder
						</Button>
					</Section.Actions>

					<Gadget 
						title="Home"
						files={files}
					/>
				</Section.Body>
			</Section>
		</LayoutBaseBrand>
	);
};

export default Invoices;
