/*
* External dependencies.
*/
import React, { useState } from 'react';
import cx from 'classnames';
import { Button, Collapse } from 'react-bootstrap';

/*
* Internal dependencies.
*/
import AvatarImage from 'images/avatar.jpg';

const WidgetProfile = () => {
	const [open, setOpen] = useState(false);

	return (
		<div className={cx('widget-profile', { 'active' : open })}>
			<div className="widget__head">
				<Button
					onClick={() => setOpen(!open)}
					aria-controls="example-collapse-text"
					aria-expanded={open}
				></Button>

				<span 
					className="avatar"
					style={{ 
						backgroundImage: `url(${AvatarImage})` 
					}}
				>
					<span className="avatar__status"></span>
				</span>

				<h5>
					Ralph Murphy

					<span>Reakt</span>
				</h5>

				<i className="ico-angle-down"></i>
			</div>
			
			<Collapse in={open}>
				<div>
					<div className="widget__body">
						<ul className="widget__actions">
							<li>
								<a href="profile-settings">Profile Settings</a>
							</li>

							<li>
								<a href="/">Switch Team</a>
							</li>
						</ul>

						<Button variant="gradient">Logout</Button>
					</div>
				</div>
			</Collapse>
		</div>
	); 
};

export default WidgetProfile;
