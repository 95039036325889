/**
 * External dependencies.
 */
import React, { useContext } from 'react';
import { Nav, Tab, Col, Row, Button, Badge, Form } from 'react-bootstrap';

/**
 * Internal dependencies.
 */
import AppContext from 'context';
import LayoutBase from 'layouts/layout-base/layout-base';
import Section from 'blocks/section/section';
import WidgetMessage from 'components/widget-message/widget-message';
import WidgetContent from 'components/widget-content/widget-content';
import Widget from 'components/widget/widget';
import File from 'components/file/file';




const ProjectDescriptionMessage = () => {
	const messages = [
		{
			"avatar" : {
				"initials" : "I",
				"color" : "#2ED9D9",
				"background" : "#ECFAF6",
			},
			"name" : "Isidro",
			"date" : "13:38",
			"subtitle" : "created a project brief",
			"file" : {
				"title" : "Project Brief",
				"subtitle" : "Landing Page Design",
				"url" : "#",
			}
		},
		{
			"avatar" : {
				"image" : require("images/superside.png")
			},
			"name" : "Superside",
			"date" : "13:38",
			"text" : "<p>Hello Isidro. <br /> Your project request, /Ironside Homepage / Desktop, has been submitted. We will be in touch shortly!</p>"
		},
		{
			"avatar" : {
				"image" : require("images/maryori.jpg")
			},
			"name" : "Maryori",
			"date" : "13:39",
			"text" : "<p>Hi Isidiro, </p><p>Thank you for your project request!</p><p>| have passed the request to your Project Manager, and they will get back to you as soon as possible. <br/>| will make Project manager sure this project is taken care of.</p><p>Is there anything | can assist with in the meantime?</p>"
		},
		{
			"avatar" : {
				"initials" : "I",
				"color" : "#2ED9D9",
				"background" : "#ECFAF6",
			},
			"name" : "Isidro",
			"date" : "13:39",
			"text" : "<p>Ok thanks. All good for now.</p>"
		},
		{
			"avatar" : {
				"image" : require("images/andrea.jpg")
			},
			"name" : "Andrea",
			"date" : "17:09",
			"text" : "<p>Hi Isidro, <br />Thank you for your patience. I reviewed your project requirements with our design team, and we can move forward with project.</p><p>For this responsive desktop landing page, we will require between 30-34 hours of Creative work. This </p>"
		}
	]

	const team = [
		{
			"avatar" : {
				"image" : require("images/andrea1.jpg")
			},
			"name" : "Andrea Perdomo",
			"title" : "Project Manager",
			"url" : "#"
		},
		{
			"avatar" : {
				"initials" : "I",
				"color" : "#2ED9D9",
				"background" : "#ECFAF6",
			},
			"name" : "Isidro Hurtado",
			"title" : "Owner",
			"url" : "#"
		},
		{
			"avatar" : {
				"initials" : "G",
				"color" : "#993BC6",
				"background" : "#F7F0FB",
			},
			"name" : "Gracie Robles",
			"title" : "Collaborator", 
			"url" : "#"
		}
	]

	const files = [
		{
			"image" : require("images/pdf.png"),
			"title" : "reakt-56175-ironsideh.zip",
			"updated" : false,
			"url" : "file-specific"
		},
		{
			"title" : "Homepage_Mobile_V2.png",
			"updated" : false,
			"url" : "file-specific"
		},

		{
			"title" : "Homepage_Mobile_V1.png",
			"updated" : false,
			"url" : "file-specific"
		},

		{
			"title" : "Ironside_Homepage_7.jpg",
			"updated" : false,
			"url" : "file-specific"
		},
		{
			"image" : require("images/f1.jpg"),
			"title" : "Ironside_Homepage_Superside_V6.jpg",
			"updated" : true,
			"url" : "file-specific"
		},
		{
			"image" : require("images/f2.jpg"),
			"title" : "Ironside_Homepage_Superside_V5.jpg",
			"updated" : false,
			"url" : "file-specific"
		},
		{
			"title" : "Ironside_Homepage_Superside_V4.jpg",
			"updated" : false,
			"url" : "file-specific"
		},
		{
			"image" : require("images/f3.jpg"),
			"title" : "Ironside_Homepage_Superside_V3.jpg",
			"updated" : false,
			"url" : "file-specific"
		},
		{
			"title" : "Ironside_Homepage_Superside_V2.jpg",
			"updated" : false,
			"url" : "file-specific"
		},
		{
			"title" : "Ironside_Homepage_Superside_V1.jpg",
			"updated" : false,
			"url" : "file-specific"
		}
	]
	
	return (
		<LayoutBase>
			<Section className="section--description">
				<Section.Head>
					<Button variant="def" href="/">
						<i className="ico-angle-left"></i>

						56175 - Ironside Homepage / Desktop
					</Button>		

					<h6>Landing Page Design</h6>
				</Section.Head>

				<Section.Body>
					<Row>
						<Col md={3}>
							<Section.Aside>
								<Widget 
									title="Overview"
									data={{
											"status": "Closed",
											"deadline": "17 Nov, 1:38 PM",
											"category": "Landing Page Design",
									}}
									showActions={true}
								/>

								<Widget 
									title="Team"
									team={team}
									enableAdd={true}
								/>
							</Section.Aside>
						</Col>

						<Col md={9}>
							<Section.BodyInner>	
								<div className="tabs-alt">
									<Tab.Container defaultActiveKey="first" className="tabs">
										<Nav className="tab-head">
											<Nav.Item>
												<Nav.Link eventKey="first">Messages</Nav.Link>
											</Nav.Item>
											
											<Nav.Item>
												<Nav.Link eventKey="second">Description</Nav.Link>
											</Nav.Item>

											<Nav.Item>
												<Nav.Link eventKey="third">Files & Assets</Nav.Link>
											</Nav.Item>
										</Nav>

										<Tab.Content>
											<Tab.Pane eventKey="first">
												<WidgetMessage 
													date="Thursday, November 12th"
													messages={messages}
												/>
											</Tab.Pane>
											
											<Tab.Pane eventKey="second">
												<WidgetContent 
													title="Brief summary"
													subtitle="New Project Request"
												>	
													<h4>
														<span>Category</span>
													</h4>

													<p>
														Landing Page Design
													</p>

													<h4>Project Name</h4>

													<p>
														Ironside Homepage
													</p>

													<h4>Description</h4>

													<p>
														Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in quam sit amet velit sodales varius nec ac elit. Cras volutpat blandit facilisis. Nulla facilisi. Aenean eu pretium elit. Cras varius dictum leo, in feugiat mi vehicula eget. Donec pretium erat vitae iaculis dictum. Pellentesque a felis magna. In quis elementum quam. Curabitur cursus dui libero.
													</p>

													<p>
														Suspendisse pellentesque, elit ut molestie porttitor, magna metus placerat sapien, eu aliquet orci lorem vitae mi. Vestibulum non tellus auctor, gravida velit eu, malesuada nunc. Ut ut nulla arcu. Nunc ut arcu ac nunc tincidunt lobortis. Aenean vitae luctus ante, quis egestas nisi. Aenean eget feugiat ligula. Ut sit amet risus sit amet dui bibendum efficitur. Aenean egestas eros velit, vel sollicitudin est ullamcorper non. 
													</p>

													<p>
														Aliquam hendrerit nisi in eros rhoncus, ut venenatis turpis sodales. Maecenas in lacinia tortor. Donec efficitur tellus purus, nec facilisis diam convallis ac. Integer sed accumsan diam.
													</p>
												</WidgetContent>	
											</Tab.Pane>

											<Tab.Pane eventKey="third">
												<Form className="form form--upload">
													<Form.Group className="form-group file-area">
														<div className="form-group-inner">
															<p>Drag files here to add them to this project, or</p>

															<div className="form-group-actions">
																<Form.Label htmlFor="upload">Browse Files</Form.Label>

																<Button variant="teritary">Import from Brand Assets</Button>
															</div>
														</div>
														
														<Form.Control 
															id="upload"
															type="file" 
															placeholder="Give your project a name" 
														/>
													</Form.Group>
												</Form>

												<div className="files">
													<Form className="form-search">
														<Form.Group>
															<Form.Control type="text" placeholder="Search by file name" />
														</Form.Group>

														<Button variant="icon" type="submit">
															<i className="ico-search-alt"></i>
														</Button>
													</Form>

													

													{files?.map((file, index) => {
														return (
															<File 
																key={index}
																image={file.image}
																title={file.title}
																updated={file.updated}
																url={file.url}
															/>
														) 
													})}
												</div>
											</Tab.Pane>
										</Tab.Content>
									</Tab.Container>
								</div>
							</Section.BodyInner>
						</Col>
					</Row>
				</Section.Body>
			</Section>
		</LayoutBase>
	);
};

export default ProjectDescriptionMessage;
