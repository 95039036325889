/*
* External dependencies.
*/
import React, {useState} from 'react';

/*
* Internal dependencies.
*/
import Folder from 'images/folder.png';

const Notification = ({title, text, height}) => {
    const [isNotificationShown, setIsNotificationShown] = useState(true);

    function closeNotification(){
        setIsNotificationShown(false);
    }

    return (
        <div className={`notification ${isNotificationShown? '' : 'notification-hidden'}`} style={{maxHeight: height + 'px'}}>
            <div>
                <img src={Folder} alt=""/>

                <button className="btn" onClick={closeNotification}>
                    <i className="ico-x"></i>
                </button>

                <h4>{title}</h4>

                <p>{text}</p>
            </div>
        </div>
    );
};

export default Notification;
