/*
* External dependencies.
*/
import React, { useState } from 'react';
import cx from 'classnames';
import { Button, Form, Badge } from 'react-bootstrap';
import selectStyles from "../../helpers/dropdown";
import Select from "react-select";

const Report = () => {
	const months = [
		{value: '', label: 'September 2021'},
		{value: '', label: 'October 2021'},
		{value: '', label: 'November 2021'},
		{value: '', label: 'December 2021'}
	]

	return (
		<div className="report">
			<div className="report__item">
				<div className="report__content">
					<h3>Subscription Balance</h3>

					<h4>Solution: <span>None, Level N/A</span></h4>
				</div>

				<Form className="form">
					<Form.Group className="form-group">
						<Form.Label htmlFor="month">Month</Form.Label>
						
						<Select options={months} styles={selectStyles}>
							<option value="">October 2021</option>
							
							<option value="1">October 2021</option>
							
							<option value="2">October 2021</option>
							
							<option value="3">October 2021</option>
						</Select>
					</Form.Group>
				</Form>
			</div>

			<div className="report__item">
				<div className="report__content">
					<h3>Hours</h3>

					<p>
						A summary of your hours and usage for this month <br />
						<strong>Fast turnaround</strong> hours (deadline within 12-24 hours) are included in your total hours, not counted separately.
					</p>

					<p>
						Hours only roll forward while the Subscription remains active
					</p>

					<Badge bg="purple">No credit data for the selected month</Badge>
				</div>
			</div>

			<div className="report__item">
				<div className="report__content">
					<h3>Projects</h3>

					<p>
						A summary of all projects worked on within the selected month. <br />
						If more hours are logged than available under your subscription solution, they will be billed at our Pay-As-You-Go rates
					</p>
				</div>

				<div className="widget-info">
					<div className="widget__body">
						<h4>This Month</h4>

						<ul>
							<li>Project</li>
							
							<li>Owner</li>
							
							<li>Delivery</li>
							
							<li>Covered hours</li>
							
							<li>Overage hours</li>
							
							<li>Overage rate</li>
							
							<li>Overage cost</li>
						</ul>

						<ul>
							<li>Totals</li>
							
							<li></li>
							
							<li></li>
							
							<li>0.00</li>
							
							<li>0.00</li>
							
							<li></li>
							
							<li>$0.00</li>
						</ul>
					</div>

					<div className="widget__aside">
						<h4>Project Total</h4>

						<ul>
							<li>Covered hours</li>
							
							<li>Overage hours</li>
							
							<li>Overage cost</li>
						</ul>
					</div>
				</div>
			</div>

			<div className="report__foot">
				<Button variant="green">Export to CSV</Button>

				<p>
					Information provided is only for reporting purposes and may not represent the invoiced amount. View your invoices or contact <a href="mailto:payment@superside.com">payment@superside.com</a> for more details on your invoices and payments. In some cases, these figures may not reflect our standard plan values due to custom agreements.
				</p>
			</div>
		</div>
	); 
};

export default Report;
