/**
 * External dependencies.
 */
import React, {useContext, useState} from 'react';
import { Nav, Tab, Button, Table, Badge } from 'react-bootstrap';

/**
 * Internal dependencies.
 */
import AppContext from 'context';
import LayoutBaseTeam from 'layouts/layout-base-team/layout-base-team';
import Section from 'blocks/section/section';
import Report from 'blocks/report/report';
import Member from 'components/member/member';
import ModalAddMembers from 'components/modal-add-members/modal-add-members';

const TeamMembers = () => {
	const { dispatchEvent } = useContext(AppContext);
	const [members, setMembers] = useState([
		{
			"id": 1,
			"avatar" : {
				"initials" : "I",
				"color" : "#2ED9D9",
				"background" : "#ECFAF6",
			},
			"name" : "Isidro Hurtado",
			"email" : "ihurtado@reakt.com",
			"admin" : true
		},
		{
			"id": 2,
			"avatar" : {
				"image" : require("images/andrea1.jpg")
			},
			"name" : "Andrea Perdomo",
			"email" : "aperdomo@reakt.com",
			"admin" : false
		},
		{
			"id": 3,
			"avatar" : {
				"initials" : "G",
				"color" : "#993BC6",
				"background" : "#F7F0FB",
			},
			"name" : "Gracie Robles",
			"email" : "grobles@reakt.com",
			"admin" : false
		}
	]);

	function deleteMember(memberId){
		setMembers(members.filter((el) => {
			return el.id !== memberId;
		}));
	}

	return (
		<LayoutBaseTeam>
			<Section className="section--team">
				<Section.Head>
					<Button variant="green" onClick={() => {dispatchEvent('TOGGLE_MODAL_MEMBERS');}}>
						Invite Members
					</Button>

					<h1>Reakt</h1>
				</Section.Head>

				<Section.Body>
					<div className="tabs">
						<Tab.Container defaultActiveKey="first" className="tabs">
							<Nav className="tab-head tab-head--primary">
								<Nav.Item>
									<Nav.Link eventKey="first">Members</Nav.Link>
								</Nav.Item>
								
								<Nav.Item>
									<Nav.Link eventKey="second">Join requests</Nav.Link>
								</Nav.Item>
							</Nav>

							<Tab.Content>
								<Tab.Pane eventKey="first">
									<div className="members">
										{members?.map((member, index) => {
											return (
												<Member
													key={index}
													avatar={member.avatar}
													name={member.name}
													email={member.email}
													admin={member.admin}
													onClickFn={() => deleteMember(member.id)}
												/>
											) 
										})}
									</div>
								</Tab.Pane>

								<Tab.Pane eventKey="second">
									<div className="members members--alt">
										<div className="members__content">
											<i className="ico-user-big"></i>

											<h4>You have all caught up!</h4>

											<p>New team requests that require approval will be shown here</p>
										</div>
									</div>
								</Tab.Pane>
							</Tab.Content>
						</Tab.Container>
					</div>
				</Section.Body>
			</Section>

			<ModalAddMembers />
		</LayoutBaseTeam>
	);
};

export default TeamMembers;
