/*
* External dependencies.
*/
import React, { useState, useContext } from 'react';
import cx from 'classnames';
import { Button, Nav } from 'react-bootstrap';

/*
* Internal dependencies.
*/
import AppContext from 'context';
import Logo from 'components/logo/logo';
import AvatarImage from 'images/avatar.jpg';
import WidgetProfile from 'components/widget-profile/widget-profile';

const Header = ( { activeNavLink } ) => {
	const { dispatchEvent }             = useContext(AppContext);
	const [openProfile, setOpenProfile] = useState(false);
	const [openMenu, setOpenMenu]       = useState(false);

	return (
		<header className={cx('header', { 'show-profile' : openProfile, 'show-menu' : openMenu })}>
			<Logo />

			<div className="header__actions">
				<Button 
					variant="burger"
					onClick={() => setOpenMenu(!openMenu)}
				>
					<i className="ico-burger">
						<span></span>
						
						<span></span>
						
						<span></span>
					</i>
				</Button>

				<span 
					onClick={() => setOpenProfile(!openProfile)}
					className="avatar"
					style={{ 
						backgroundImage: `url(${AvatarImage})` 
					}}
				></span>
			</div>

			<div className="header__body">
				<Button 
					variant="gradient-border"
					onClick={() => {dispatchEvent('TOGGLE_MODAL');}} 
				>
					<span>
						<i className="ico-plus"></i> New Project
					</span>
				</Button>

				<Nav defaultActiveKey={activeNavLink ? activeNavLink : "/"}>
					<Nav.Link href="/">
						<span>
							<i className="ico-projects"></i>

							<i className="ico-projects-1"></i>
						</span>

						Projects
					</Nav.Link>
					
					<Nav.Link eventKey="link-1" href="team-members">
						<span>
							<i className="ico-team"></i>
							
							<i className="ico-team-1"></i>
						</span>

						Team
					</Nav.Link>
					
					<Nav.Link eventKey="link-2" href="brand">
						<span>
							<i className="ico-folder"></i>
							
							<i className="ico-folder-1"></i>
						</span>

						Brand Assets
					</Nav.Link>
					
					<Nav.Link eventKey="link-3" href="invoices">
						<span>
							<i className="ico-console"></i>
							
							<i className="ico-console-1"></i>
						</span>

						Admin Console
					</Nav.Link>
				</Nav>
			</div>

			<aside className="header__aside">
				<Button variant="link">
					<i className="ico-burger">
						<span></span>
						
						<span></span>
						
						<span></span>
					</i> 

					Hide Menu
				</Button>

				<WidgetProfile />
			</aside>
		</header>
	); 
};

export default Header;
