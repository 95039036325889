/*
* External dependencies.
*/
import React from 'react';
import { Button, Badge } from 'react-bootstrap';

const Member = ({id, avatar, name, email, admin, onClickFn}) => {
	return (
		<div className="member">
			{avatar.image ? (
				<div 
					className="avatar-alt"
					style={{ backgroundImage: `url(${avatar.image})` }}
				></div>
			) : (
				<div 
					className="avatar-alt"
					style={{backgroundColor: avatar.background, color: avatar.color, }}
				>{avatar.initials}</div>
			)}

			<h4>{name} {admin && (<span>You</span>)}</h4>

			<h6>{email}</h6>

			<div className="member__actions">
				{admin && ( <Badge bg="green">Admin</Badge> )}

				<Button variant="link"><i className="ico-edit"></i></Button>
				
				<Button variant="link" onClick={() => onClickFn()}><i className="ico-bin"></i></Button>
			</div>
		</div>
	); 
};

export default Member;
