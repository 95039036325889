/**
 * External dependencies.
 */
import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';

/**
 * Internal dependencies.
 */
import AppContext from 'context';
import LayoutBase from 'layouts/layout-base/layout-base';
import Section from 'blocks/section/section';
import WidgetSettings from 'blocks/widget-settings/widget-settings';


const ProfileSettings = () => {
	const { dispatchEvent }             = useContext(AppContext);
	
	return (
		<LayoutBase>
			<Section className="section--teritary">
				<Section.Head>
					<h1>Profile Settings</h1>
				</Section.Head>

				<Section.Body>
					<WidgetSettings />
				</Section.Body>
			</Section>
		</LayoutBase>
	);
};

export default ProfileSettings;
