/**
 * External dependencies.
 */
import React, { useContext } from 'react';
import { Nav, Tab, ButtonGroup, Button, Table, Badge } from 'react-bootstrap';

/**
 * Internal dependencies.
 */
import AppContext from 'context';
import LayoutBase from 'layouts/layout-base/layout-base';
import Section from 'blocks/section/section';

const MyProjects = () => {
	const { dispatchEvent }             = useContext(AppContext);

	return (
		<LayoutBase>
			<Section>
				<Section.Head>
					<h1>Projects</h1>
				</Section.Head>

				<Section.Body>
					<div className="tabs">
						<Tab.Container defaultActiveKey="first" className="tabs">
							<Nav className="tab-head">
								<Nav.Item>
									<Nav.Link eventKey="first">All Projects <i className="ico-angle-down-dark"></i></Nav.Link>
								</Nav.Item>
								
								<Nav.Item>
									<Nav.Link eventKey="second">Status <i className="ico-angle-down-dark"></i></Nav.Link>
								</Nav.Item>

								<Nav.Item>
									<Button variant="link-alt">Search <i className="ico-search"></i></Button>
								</Nav.Item>

								<ButtonGroup>
									<Button variant="icon active">
										<i className="ico-grid"></i>
									</Button>

									<Button variant="icon">
										<i className="ico-grid-box"></i>
									</Button>
								</ButtonGroup>
							</Nav>

							<Tab.Content>
								<Tab.Pane eventKey="first">
									<Table>
										<thead>
											<tr>
												<th>
													<i 
														className="ico-circle"
														style={{
															backgroundColor: '#CFCADD'
														}}
													></i>

													Project Name

													<i className="ico-sort"></i>
												</th>
												
												<th>
													Status

													<i className="ico-sort"></i>
												</th>
												
												<th> 
													Deadline

													<i className="ico-sort"></i>
												</th>
												
												<th>
													Category

													<i className="ico-sort"></i>
												</th>
											</tr>
										</thead>

										<tbody>
											<tr>
												<td>
													<h4>
														<i 
															className="ico-circle"
															style={{
																backgroundColor: '#993BC6'
															}}
														></i>

														Ironside Homepage Layout

														<span>You: <em>Thanks Andrea!</em></span>
													</h4>
												</td>

												<td>
													<Badge bg="purple">Draft</Badge>
												</td>

												<td>
													<p>No Deadline</p>
												</td>

												<td>
													<p>Landing Page Design</p>
												</td>
											</tr>

											<tr>
												<td>
													<h4>
														<i 
															className="ico-circle"
															style={{
																backgroundColor: '#2FD9D9'
															}}
														></i>

														Headstrong Concepts

														<span>You: <em>All layout approved. We...</em></span>
													</h4>
												</td>

												<td>
													<Badge bg="green">Closed</Badge>
												</td>

												<td>
													<p>17 Nov, 1:38PM</p>
												</td>

												<td>
													<p>Web Design</p>
												</td>
											</tr>
										</tbody>
									</Table>
								</Tab.Pane>
								
								<Tab.Pane eventKey="second">
									<h1>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odit eos consectetur veniam saepe culpa corporis, sint Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque doloremque ex fugiat laudantium quidem dolor perspiciatis, architecto modi vitae at iusto, commodi maiores et. Corporis quos blanditiis ratione nisi reprehenderit. modi similique cumque asperiores sapiente minus velit porro excepturi quae harum, itaque inventore libero!</h1>
								</Tab.Pane>
							</Tab.Content>
						</Tab.Container>
					</div>
				</Section.Body>
			</Section>
		</LayoutBase>
	);
};

export default MyProjects;
