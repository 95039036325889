/*
* External dependencies.
*/
import React from 'react';
import { Link } from "react-router-dom";

/*
* Internal dependencies.
*/
import LogoImage from 'images/logo.svg';

const Logo = () => {
	return (
		<Link to="/" className="logo">
			<img src={LogoImage} alt="" />
		</Link>
	); 
};

export default Logo;
