/*
* External dependencies.
*/
import React, { useState } from 'react';
import cx from 'classnames';
import { Badge, Button, Collapse } from 'react-bootstrap';

const Widget = ({ title, data, team, showActions, enableAdd }) => {
	const [open, setOpen] = useState(false);

	return (
		<div className={cx('widget', { 'is-active' : open })}>
			<h4 className="widget__title">
				{title}

				<Button
					onClick={() => setOpen(!open)}
					aria-expanded={open}
				/>
			</h4>
			
			<Collapse in={open}>
				<div className="widget__body">
					{data && (
						<ul className="widget__data">
							<li>
								<strong>Status</strong>  

								{data.status === "Closed" && ( <Badge bg="secondary">Closed</Badge> )}
								{data.status === "Open" && ( <Badge bg="green">Open</Badge> )}
							</li>
						
							<li>
								<strong>Deadline</strong> {data.deadline}
							</li>
						
							<li>
								<strong>Category</strong>  {data.category}
							</li>
						</ul>
					)}

					{showActions && (
						<ul className="widget__actions">
							<li>
								<Button>
									<i className="ico-upload"></i>
								</Button>
							</li>

							<li>
								<Button>
									<i className="ico-edit"></i>
								</Button>
							</li>

							<li>
								<Button>
									<i className="ico-check-alt"></i>
								</Button>
							</li>

							<li>
								<Button>
									<i className="ico-bin"></i>
								</Button>
							</li>
						</ul>
					)}

					{team?.map((item, index) => {
						return (
							<div className="widget__item" key={index}>
								{item.avatar.image ? (
									<div 
										className="widget__avatar"
										style={{ backgroundImage: `url(${item.avatar.image})` }}
									></div>
								) : (
									<div 
										className="widget__avatar"
										style={{backgroundColor: item.avatar.background, color: item.avatar.color, }}
									>{item.avatar.initials}</div>
								)}

								<h6>
									{item.name}

									<span>{item.title}</span>
								</h6>
							</div>
						) 
					})}
	 
					{enableAdd && (
						<Button variant="outline-secondary">
							<i className="ico-plus"></i> Add collaborator
						</Button>
					)}
				</div>
			</Collapse>
		</div>
	); 
};

export default Widget;
